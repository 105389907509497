body {
  padding-top: 70px;
}
.container-narrow {
  margin: 0 auto;
  max-width: 700px;
}
.full-width {
  width: 100%;
}
.large-font {
  font-size: 50px;
}
.highlight {
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 4px;
  margin-bottom: 14px;
  padding: 9px 14px;
}
.highlight pre {
  background-color: transparent;
  border: 0 none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  white-space: nowrap;
  word-break: normal;
}
.code-section {
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 4px;
  margin-bottom: 14px;
  padding: 9px 14px;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.white {
  color: #fff;
}
.auto-cursor {
  cursor: auto !important;
}
.default-bottom-margin {
  margin-bottom: 20px;
}
.img-polaroid {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-right: 15px;
  padding: 4px;
}
.firstcharacter {
  color: #dd4b39;
  float: left;
  font-family: Georgia;
  font-size: 75px;
  line-height: 60px;
  padding-left: 3px;
  padding-right: 8px;
  padding-top: 4px;
}
#CategoryArticles {
  margin-bottom: 80px;
  display: table;
}
article {
  margin-bottom: 80px;
}
article p {
  line-height: 1.3em;
  margin-bottom: 12px;
}
article h4,
article h5,
article h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.label {
  font-size: 12px;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}
.label a {
  color: #fff;
}
.label-highlight {
  background-color: #f3576a;
}

/*# sourceMappingURL=common.css.map */