#Article {
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0 10px;
}
#ArticleTitle {
  margin-bottom: 0.3em;
}
#ArticleBody {
  color: #737373;
  font-size: 1.1em;
  margin-bottom: 0.3em;
}
.date {
  color: #aaa;
  font-size: 0.85em;
}
.title {
  color: #07c;
  font-size: 1.3em;
}

/*# sourceMappingURL=articles.css.map */