.footer {
  border-top: 1px solid #eee;
  bottom: 0px;
  height: 60px;
  position: absolute;
  width: 100%;
  padding: 20px 0px;
}
html {
  min-height: 100%;
  position: relative;
}
body {
  margin-bottom: 60px;
}
.page-row {
  border: 0px solid #ccc;
}
.page-block {
  margin-bottom: 2em;
  border: 0px solid #666;
}
.page-block .full-center {
  cursor: pointer;
  height: 150px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 100%;
}
.full-center {
  display: table;
}
.page-block-inner {
  border: 0px solid green;
}
.page-block-thumbnail {
  margin-bottom: 25px;
  text-align: center;
}
.page-block-title {
  color: #fff;
  font-size: 1.2em;
  letter-spacing: -0.5px;
  line-height: 1.2em;
  margin-bottom: 0px;
  max-width: 100%;
  font-weight: bold;
  position: relative;
  text-align: left;
}
.page-block-description {
  padding: 10px 0px 7px;
  line-height: 1.4em;
  color: #9a9a9a;
  font-size: 0.95em;
}
.page-block a,
.page-block a:hover {
  color: #fff;
}
.page-block-status {
  background: none repeat scroll 0 0 #9bc84a;
  color: #fff !important;
  font-size: 10px !important;
  padding: 4px 4px !important;
  position: absolute;
  text-transform: uppercase;
  top: 9px;
  z-index: 2;
}
.page-block-published {
  color: #dc143c !important;
  font-size: 10px !important;
  font-weight: bold;
  padding: 4px 4px !important;
  position: absolute;
  top: 9px;
  z-index: 2;
  right: 6px;
}
.navbar-default {
  border-top: 2px solid #dc143c;
}
.brand {
  color: #dc143c !important;
}
.search-output {
  display: none;
}
.tab-pane {
  background-color: #fff;
  border: 1px solid #e1e1e8;
  border-top: 0px;
  border-radius: 4px;
  padding: 20px;
}
.notification {
  background: #dc143c;
  color: white;
  width: 200px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 1em;
  display: none;
  z-index: 1000;
}
.horizontal-block {
  padding-top: 25px;
}
.shadow-block {
  background-color: #f9f9f9;
}
.first-block {
  margin-top: -18px;
}
/* Typing Page */
.panel-default > .panel-heading {
  background-color: #fff0ff;
  border-bottom: 1px solid #cccccc;
  background-image: url("/images/easylanguagetyping/bg/default.png");
}
.panel-default {
  border: 1px solid #cccccc !important;
}
.panel {
  border-radius: 0px;
}
.editor-toolbar {
  height: 30px;
}
.editor-content {
  padding-bottom: 15px;
}
#languageTextarea {
  width: 100%;
  height: 240px;
  color: #444;
  font-weight: bold;
  font-size: 120% !important;
  border: 0px;
  background-color: #fff;
  resize: vertical;
}
#languageDropDown {
  border: 1px solid #dddddd;
  border-radius: 2px;
  height: 31px;
  background-color: #fff;
  color: #444444;
}
.full-width {
  width: 100%;
}

/*# sourceMappingURL=site.css.map */