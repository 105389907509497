#ArticleModern {
  /*border-bottom: 1px solid #e0e0e0;*/
  padding: 0 0 10px;
  margin: 20px 20p 40px 20px;
}
#ArticleModern p {
  color: #666;
  font-size: 1.3em;
  margin-bottom: 12px;
}
#ArticleModern .question {
  margin: 30px 0px;
}
#ArticleModern .question p,
#ArticleModern table {
  font-size: 1.3em;
}
#ArticleModern caption {
  font-size: 0.9em;
  font-style: italic;
}

/*# sourceMappingURL=articles-modern.css.map */